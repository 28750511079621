<template>
  <BasePage :loading="loading">
    <Container>
      <v-row class="mt-5" v-if="courses.length && !loading">
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-for="course in courses"
          :key="course.id"
        >
          <ProductItem :sold="true" :product="course" :type="+type" />
        </v-col>
      </v-row>
      <v-row v-else justify="center" align="center" style="height:300px">
        <div class="text-center">
          <v-icon color="primary" style="fontSize:40px"
            >mdi-timer-sand-empty</v-icon
          >
          <div class="body-1 mt-4 primary--text">
            {{ type == 2 ? $t('noPrevTests') : $t('noCourses') }}
          </div>
        </div>
      </v-row>
    </Container>
  </BasePage>
</template>

<script>
import ApiService from '@/services/api.service';
import ProductItem from '@/components/ProductItem';
import { mapGetters } from 'vuex';
export default {
  components: {
    ProductItem,
  },
  data() {
    return {
      loading: false,
      courses: [],
      type: this.$route.query.type,
    };
  },
  methods: {
    async getCourses() {
      // console.log(this.userGetter.successStatuses.toString());
      try {
        this.loading = true;
        // let arr = [];
        const { data } = await ApiService.get(
          `items/enrollment?fields=*,course_id.*,course_id.featured_image.data.full_url,result_option_id,&sort=created_on&filter[student_id.id]=${
            this.userGetter.currentUser.id
          }&filter[course_id.type_id.id]=${
            this.type
          }&filter[payment_status][in]=${this.userGetter.successStatuses.toString()}`
        );

        this.courses = data.data;

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    '$route.query.type'(newType) {
      this.type = newType;
      this.getCourses();
    },
  },
  computed: {
    ...mapGetters(['userGetter']),
  },
  mounted() {
    this.getCourses();
  },
};
</script>

<style></style>
